// 角色和权限相关接口
import request from '@/request/request'
// 获取公钥的接口
export const PublicKeyApi = () => request.get('/frame-web/admin/sys/user/public/key');
// 获取当前用户权限菜单
export const UserPermissionApi = () => request.get('/frame-web/admin/sys/user/permission');



// 角色列表接口
export const RoleListApi = (params) => request.post('/frame-web/admin/sys/role', params);
// 树形列表接口
export const TreeListApi = () => request.get('/frame-web/admin/sys/menu/tree/copy');
// 新增角色接口  
export const RoleAddApi = (params) => request.post('/frame-web/admin/sys/role/add', params);
// 编辑角色接口
export const RoleUpdateApi = (params) => request.post(`/frame-web/admin/sys/role/update/${params.id}`, params);
// 删除角色接口
export const RoleDeleteApi = (params) => request.post(`/frame-web/admin/sys/role/delete/${params.id}`, params);
// 获取角色详情接口
export const RoleDetailApi = (params) => request.get(`/frame-web/admin/sys/role/detail/${params.id}`, { params });



// 角色列表下拉栏
export const RoleSelectLabelApi = (params) => request.get(`/frame-web/admin/sys/role/select/label`, { params });
// 用户列表接口
export const userListApi = (params) => request.post(`/frame-web/admin/sys/user`, params);
// 新增用户接口
export const userAddApi = (params) => request.post(`/frame-web/admin/sys/user/add`, params);
// 编辑用户接口
export const userUpdateApi = (params) => request.post(`/frame-web/admin/sys/user/update/${params.id}`, params);
// 用户详情接口
export const userDetailApi = (params) => request.get(`/frame-web/admin/sys/user/${params.id}`, {params});
//删除用户接口
export const userDeleteApi = (params) => request.post(`/frame-web/admin/sys/user/delete/${params.id}`, params);
// 禁用用户接口
export const userDisableApi = (params) => request.post(`/frame-web/admin/sys/user/disable/${params.id}`, params);
// 启用用户接口
export const userEnableApi = (params) => request.post(`/frame-web/admin/sys/user/enable/${params.id}`, params);
// 重置密码接口
export const userResetPasswordApi = (params) => request.post(`/frame-web/admin/sys/user/reset/password/${params.id}`, params);
