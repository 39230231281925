import { render, staticRenderFns } from "./RoleManage.vue?vue&type=template&id=1547e109&scoped=true&"
import script from "./RoleManage.vue?vue&type=script&lang=js&"
export * from "./RoleManage.vue?vue&type=script&lang=js&"
import style0 from "./RoleManage.vue?vue&type=style&index=0&id=1547e109&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1547e109",
  null
  
)

export default component.exports